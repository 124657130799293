<template>
  <div class="selectUser">
    <Layout>
      <Layout>
        <Content style="background:white;padding-left:20px">
          <div class="content">
            <div class="tabList">
             <div style="display:flex">
                  <Cascader  @on-change="changeCasder" :load-data="loadData" change-on-select style=" width: 200px" :data="CascaderList" v-model="CascaderId" placeholder="请选择区/街道/社区"></Cascader>
                    <Input
                        :maxlength="20"
                        enter-button
                        v-model.trim="searchData.name"
                        placeholder="姓名"
                        style="width: 150px; margin: 0 10px 0 20px"
                    />
                    <Button type="primary" icon="ios-search" @click="searchBtn"
                        >查询</Button
                    >
                    <Button
                        style="margin: 0 5px 0 10px"
                        type="success"
                        icon="ios-refresh"
                        @click="rest"
                        >重置</Button
                    >
                    <Button type="info" style="margin: 0 5px 0 10px" @click="changeAll">全选所有</Button>
             </div>
             
              <Table
                v-if="showTable"
                :loading="tableLoading"
                ref="selection"
                stripe
                :height="tableHeight"
                :columns="talbeColumns"
                :data="tableData"
                @on-select="onSelect"
                @on-select-cancel="onSelectCancel"
                @on-select-all="onSelectAll"
                @on-select-all-cancel="onSelectAllCancel"
                style="margin-top:20px"
              ></Table>
              <Page
                class="page"
                show-total
                @on-change="currentPage"
                @on-page-size-change="hadlePageSize"
                :total="total"
                size="small"
                :page-size.sync="pageSize"
                :current="page"
                show-elevator
              />
            </div>
            <div class="buttonList">
              <Button @click="removeAll">清空</Button>
            </div>
            <div class="selectList">
              <Tree :data="sortSelect"></Tree>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableLoading:false,
      treeHeight: '',
      tableHeight: '',
      total: 0,
      pageSize: 20,
      page: 1,
      selection: [],
      sortSelect: [],
      nodeList: [
        // {
        //     value: "New York",
        //     label: "纽约",
        // },
      ],
      searchData: {
        name: "",
      },
      // 树形控件data
      talbeColumns: [
        {
          type: "selection",
          width: 80,
          align: "center",
        },
        {
          title: "姓名",
          key: "name",
          align: "center",
          width: 200,
        },
        {
          title: "性别",
          key: "sex",
          align: "center",
          width: 140,
        },
        {
          title: "手机号",
          key: "phone",
          align: "center",
          minWidth: 200,
        },
      ],
      tableData: [],
      treeData: [],

     
      CascaderList:[],
      CascaderId:[],

      showTable:true,
      isNum:0,
      // communityCode:'',
    };
  },
  created(){
      this.getCascader()
      
  },
  methods: {
    // 点击全选按钮
    changeAll(){
      if(this.CascaderId.length != 0 && this.CascaderId.length != 3){
            this.$Message.warning({
                content:'请选择到社区进行全选',
                background:true
            })
            return 
         }
      this.$emit("getCommunityCode", this.CascaderId[this.CascaderId.length - 1]);
      this.sortSelect.map((item,index) =>{
        if(item.id == 'all'){
          this.sortSelect.splice(index,1)
        }
      })
      this.sortSelect.push({title:'全部',id:'all'})
      this.sortSelect = this.quchong(this.sortSelect)
      this.$emit("handleSelect", this.sortSelect);
    },
    // 异步加载级联
    loadData (item, callback) {
      item.loading = true
      let params = {
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode:parent.vue.oemInfo.oemCode,
        orgCode:item.orgCode,
        orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
      };
       this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
        ...params
      }).then(res=>{
         if(res.code == 200 && res.dataList.length != 0){
           let arr = this.forMatterTree(res.dataList)
           arr.map(items =>{
             if(items.orgLevel == 4){
               items.loading = false
             }
           })
           item.loading = false
           item.children = arr
           callback(item.children);
           if(this.isNum == 0 && item.children.length != 0){
             this.CascaderId.push(item.children[0].value)
             this.isNum = 1
             console.log('二',this.CascaderId);
             return
           }else if(this.isNum == 1 && item.children.length != 0){
             this.CascaderId.push(item.children[0].value)
             this.isNum = 0
             console.log(item.children[0].value)
             this.getList(item.children[0].value)
              console.log('三',this.CascaderId);
           }
         }else{
           callback([]);
         }
      })
    },
    // 点击搜索按钮
    searchBtn(){
        if(this.CascaderId.length != 0 && this.CascaderId.length != 3){
            this.$Message.warning({
                content:'请选择到社区进行查询',
                background:true
            })
            return 
         }
         this.$emit("getCommunityCode", this.CascaderId[this.CascaderId.length - 1]);
        //  this.communityCode = this.CascaderId[this.CascaderId.length - 1]
         console.log(this.CascaderId[this.CascaderId.length - 1])
         this.getList(this.CascaderId[this.CascaderId.length - 1])
    },
   // 获取级联
    getCascader(){
      this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode:parent.vue.oemInfo.oemCode,
        orgCode:'4401',
        orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
      }).then(res=>{
         if(res.code == 200 && res.dataList.length != 0){
           this.CascaderList = this.forMatterTree(res.dataList)
           this.CascaderList.map(item =>{
             item.loading = false
           })
           if(this.CascaderList.length != 0){
              this.CascaderId.push(this.CascaderList[0].value)
              console.log('一',this.CascaderId);
           }
         }
      })
    },
    forMatterTree(data) {
      for (var i = 0; i < data.length; i++) {
        data[i].label = data[i].orgName;
        data[i].value = data[i].orgCode;
         if (data[i].children && data[i].children != null && data[i].children.length > 0 ) {
              this.forMatterTree(data[i].children);
         }
      }
      return data;
    },
    getTableData(data) {
      this.tableData = data;

      this.total = data.maxCount;
      this.handleTableChecked(this.tableData); // 恢复有勾选的数据
    },
    rest() {
      this.searchData.name = "";
      this.page = 1;
      this.CascaderId = []
      this.getList();
    },
    getList(data) {
      this.tableLoading = true
      this.$get(
        "/syaa/pc/sy/user/userOriginal/selectReceiveMessagePage",
        {
            pageSize:this.pageSize,
            page: this.page,
            name: this.searchData.name,
            custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
            orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
            orgCode: data,
            }
      )
        .then((res) => {
          this.tableLoading = false
          if (res.code === "200") {
            this.tableData = res.dataList.map(item => {
                if(item.sex) {
                    item.sex = item.sex == '1' ? '男' : '女'
                }
                return item
            });
            this.getTableData(this.tableData);
            this.total = res.maxCount;
            this.curPage = res.currentPage;
            // this.treeParams.name = "";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeAll() {
      this.sortSelect = [];
      
      this.tableData.forEach((item) => {
        // 本页数据中找到该条勾选数据并添加属性_checked = true
          item["_checked"] = null 
      });
      this.showTable = false
      this.$nextTick(()=>{
        this.showTable = true
      })
      this.$emit("handleSelect", this.sortSelect);

    },
    currentPage(val) {
      //当前页
      this.page = val;
      this.getList(this.CascaderId[this.CascaderId.length - 1]);
    },
    hadlePageSize(val) {
      //每页的条数

      this.pageSize = val;
      this.getList(this.CascaderId[this.CascaderId.length - 1]);
    },

    // ---------------------------分页记忆---------------------------
    /**
     * @param {*} selection 已选项数据
     * @param {*} row 刚选择的项数据
     * @description 勾选table某一项时触发
     */
    onSelect(selection, row) {
      let obj = {
        id: row.id,
        title: row.name,
      };
      // this.tempArr.push(row.id) // 将该条数据添加到tempArr中
      this.sortSelect.push(obj); // 将该条数据添加到tempArr中
      this.tableData.forEach((item) => {
        // 本页数据中找到该条勾选数据并添加属性_checked = true
        if (item.id === row.id) {
          item["_checked"] = true;
        }
      });
      this.$emit("handleSelect", this.sortSelect);
    },

    /**
     * @param {*} selection 已选项数据
     * @param {*} row 刚取消选择的项数据
     * @description 取消table某一项时触发
     */
    onSelectCancel(selection, row) {
      this.sortSelect.forEach((item, index) => {
        // tempArr中找到该条数据并删除
        if (row.id === item.id) {
          this.sortSelect.splice(index, 1);
        }
      });
      this.$emit("handleSelect", this.sortSelect);
    },

    /**
     * @param {*} selection 已选项数据
     * @description table全选时触发
     */
    onSelectAll(selection) {
    //   this.sortSelect = [];
      selection.forEach((item) => {
        // 将本页全部勾选添加到tempArr中
        // this.tempArr.push(item.id)
        let obj = {
          id: item.id,
          title: item.name,
        };
        this.sortSelect.push(obj);
      });

    //   this.sortSelect = Array.from(new Set(this.sortSelect)); // 去重
      this.sortSelect = this.quchong(this.sortSelect)
      this.$emit("handleSelect", this.sortSelect);
    },

    /**
     * @param {*} selection 已选项数据
     * @description table取消全选时触发
     */
    onSelectAllCancel(val) {
     this.sortSelect = this.qufei(this.tableData,this.sortSelect)
    //   this.sortSelect = [];
      this.$emit("handleSelect", this.sortSelect);
    },

    /**
     * @param {*} datas 当前页数据
     * @description 还原有勾选的数据
     */
    handleTableChecked(datas) {
      this.sortSelect.forEach((item) => {
        // 判断本页数据状态
        datas.forEach((e) => {
          if (item.id === e.id) {
            e["_checked"] = true;
          }
        });
      });
    },
    qufei(array1,array2){
        let result = []
        for(var i = 0; i < array2.length; i++){
            var obj = array2[i];
            var num = obj.id;
            var isExist = false;
            for(var j = 0; j < array1.length; j++){
                var aj = array1[j];
                var n = aj.id;
                if(n == num){
                    isExist = true;
                    break;
                }
            }
            if(!isExist){
                result.push(obj);
            }
        }
        return result
    },
    quchong(data){
        let newArr = []
        let obj = {}
        for(var i =0;i<data.length;i++){
            if(!obj[data[i].id]){
                newArr.push(data[i])
                obj[data[i].id] = true
            }
        }
        return newArr
    }
  },
  beforeMount() {
      this.treeHeight = String(window.innerHeight - 152);
      this.tableHeight = String(window.innerHeight - 238);
  }
};
</script>

<style lang="less" scoped>
.selectUser {

  .ivu-layout-sider {
    background: #fff;
    min-height: 200px;
    .ivu-layout-sider-children {
     .ivu-tree {
        border: 1px solid rgba(000, 000, 000, 0.1);

        .title {
          padding: 20px;
          border-bottom: 1px solid rgba(000, 000, 000, 0.1);
        }
      }

      background: #fff;
      height: auto;
    }
  }

  .content {
    display: flex;
    height: calc(100vh - 152px);

    .tabList {
      flex: 1;
      height: 500px;
      padding-top: 10px;
      border-right: 1px solid rgba(000, 000, 000, 0.1);
    }
    /deep/.ivu-table-wrapper {
        height: calc(100vh - 234px);
        overflow: auto;
    }

    .buttonList {
      width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 500px;
      height: 100%;
    }

    .selectList {
      width:200px;
      overflow-y: scroll;
      height: 100%;

      border-radius: 0px;
      border: 1px solid rgba(000, 000, 000, 0.1);

      .ivu-checkbox-group-item {
        display: block;
        margin: 20px 50px;
      }
    }
    .page {
        margin: 10px 0 10px 10px;
    }
  }
  /deep/.ivu-tooltip-dark {
    display: none;
  }
}
</style>
